import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"

const NotFoundPage = () => (

  <Layout>
    <SEO
      title='404 - Page Not Found'
    />
    <section>
      <Container>
        <header>
          <Row>
            <Col
              xs={{ offset: 1 }}
              sm={{ size: 10, offset: 2 }}
              md={{ offset: 1 }}
            >
              <h1 className='heading1'>
                404
              </h1>
              <h2>We couldn't find that page</h2>
              <Link className="btn btn-primary mt-5 mb-5" to="/">Return Home</Link>
            </Col>
          </Row>
        </header>
      </Container>
    </section>
  </Layout>
);

export default NotFoundPage;
